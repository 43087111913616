import React, { useState, useRef, useEffect } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { BsFillPlayFill, BsFillPauseFill } from 'react-icons/bs';
// TODO extract what we need to audio styles file
// and expose a sizing prop for smaller play view
import styles from './CreateView/PlayAndSave.module.css';
import { Dialogue, ProjectImplWithStats } from '../../models/Project';
import { starSigns } from '../../utils/starSignInformation';
import { useMediaQuery } from 'react-responsive';
import { saveProject as doSaveProject } from '../../utils/backendInterface';

const PLAY_THRESHOLD = parseFloat(process.env.REACT_APP_PLAY_THRESHOLD || '1.0');
const BACKGROUND_MUSIC_VOLUME = parseFloat(process.env.REACT_APP_BACKGROUND_MUSIC_VOLUME || '0.5');

type AudioPlayerComponentProps = {
    dss: ProjectImplWithStats;
    onProjectSaved?: (project: ProjectImplWithStats) => void;
};

const AudioPlayerComponent = ({ dss, onProjectSaved }: AudioPlayerComponentProps) => {
    const currentLineRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const [outerIndex, setOuterIndex] = useState(0);
    const [innerIndex, setInnerIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const shouldPlayNextLine = useRef(false);
    const [hasStartedPlaying, setHasStartedPlaying] = useState(false);
    const audioPlayerRef = useRef(null);
    const backgroundMusicRef = useRef<HTMLAudioElement>(null);

    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

    const updatePlayStats = (currentOuterIndex: number, currentInnerIndex: number) => {
        let shouldSave = false;
        
        if (!dss.extra_data) {
            dss.extra_data = {};
        }

        if (!hasStartedPlaying) {
            dss.extra_data.starts = (dss.extra_data.starts || 0) + 1;
            shouldSave = true;
        }

        const totalDialogues = dss.scenes.reduce((sum, scene) => 
            sum + scene.items.filter(item => item.type === 'Dialogue').length, 0);
        const totalDialoguesBeforeOuterIndex = dss.scenes.slice(0, currentOuterIndex).reduce((sum, scene) =>
            sum + scene.items.filter(item => item.type === 'Dialogue').length, 0);
        const currentProgress = (totalDialoguesBeforeOuterIndex + currentInnerIndex + 1) / totalDialogues;

        if (currentProgress >= PLAY_THRESHOLD) {
            dss.extra_data.plays = (dss.extra_data.plays || 0) + 1;
            shouldSave = true;
        }

        if (shouldSave && dss.isSaved) {
            doSaveProject(dss)
                .then(savedProject => {
                    if (onProjectSaved) {
                        onProjectSaved(savedProject);
                    }
                })
                .catch(error => console.error('Error saving project:', error));
        }
    };

    useEffect(() => {
        if (!isPlaying && audioPlayerRef.current?.audio?.current && shouldPlayNextLine.current) {
            playAudio();
        }
    }, [outerIndex, innerIndex, isPlaying]);

    const playAudio = async () => {
        try {
            await audioPlayerRef.current.audio.current.play();
            // Play background music if available
            if (dss.music?.url && backgroundMusicRef.current) {
                backgroundMusicRef.current.volume = BACKGROUND_MUSIC_VOLUME;
                backgroundMusicRef.current.play();
            }
        } catch (error) {
            console.error('Error playing audio:', error);
        }
    }
    const pauseAudio = () => {
        audioPlayerRef.current.audio.current.pause();
        // Pause background music
        if (backgroundMusicRef.current) {
            backgroundMusicRef.current.pause();
        }
    }

    const handleAudioEnded = async () => {
        if (!dss) {
            return;
        }
        if (outerIndex < dss.scenes.length) {
            if (innerIndex < dss.scenes[outerIndex].items.length - 1) {
                setInnerIndex(innerIndex + 1);
                updatePlayStats(outerIndex, innerIndex + 1);
                shouldPlayNextLine.current = true;
                return;
            } else if (outerIndex < dss.scenes.length - 1) {
                setOuterIndex(outerIndex + 1);
                setInnerIndex(0);
                updatePlayStats(outerIndex + 1, 0);
                shouldPlayNextLine.current = true;
                return;
            } else {
                setOuterIndex(0);
                setInnerIndex(0);
                updatePlayStats(outerIndex, innerIndex);
                // Reset background music to beginning
                if (backgroundMusicRef.current) {
                    backgroundMusicRef.current.currentTime = 0;
                }
            }
        }
        shouldPlayNextLine.current = false;
    };

    const handlePlayPause = async () => {
        if (audioPlayerRef.current) {
            if (isPlaying) {
                pauseAudio();
                shouldPlayNextLine.current = false;
            } else {
                updatePlayStats(outerIndex, innerIndex);
                setHasStartedPlaying(true);
                await playAudio();
            }
        }
    };

    const getSignIcon = signName => {
        const sign = starSigns.find(s => s.name === signName);
        if (sign) {
            const IconComponent = sign.icon;
            return <IconComponent className={styles.vsSignIcon} />;
        }
        return null;
    };

    const getPreviousLine = (): Dialogue | null => {
        if (innerIndex > 0) {
            return dss.scenes[outerIndex].items[innerIndex - 1] as Dialogue;
        } else if (outerIndex > 0 && dss.scenes[outerIndex - 1].items.length > 0) {
            return dss.scenes[outerIndex - 1].items[
                dss.scenes[outerIndex - 1].items.length - 1
            ] as Dialogue;
        }
        return null;
    };

    const getNextLine = (): Dialogue | null => {
        if (innerIndex < dss.scenes[outerIndex].items.length - 1) {
            return dss.scenes[outerIndex].items[innerIndex + 1] as Dialogue;
        } else if (
            outerIndex < dss.scenes.length - 1 &&
            dss.scenes[outerIndex + 1].items.length > 0
        ) {
            return dss.scenes[outerIndex + 1].items[0] as Dialogue;
        }
        return null;
    };

    const previousLine = getPreviousLine();
    const currentLine =
        dss.scenes[outerIndex] && (dss.scenes[outerIndex].items[innerIndex] as Dialogue);
    const nextLine = getNextLine();

    return (
        <div className={styles.linesAndAudioWrapper}>
            {dss.music?.url && (
                <audio
                    ref={backgroundMusicRef}
                    src={dss.music.url}
                    loop
                />
            )}
            <div className={styles.scrollableLinesContainer} ref={scrollContainerRef}>
                {!hasStartedPlaying ? (
                    <div className={`${styles.lineDisplay} ${styles.currentLine}`}>
                        <div className={styles.vsDisplay}>
                            <div className={styles.vsSign}>{getSignIcon(dss.firstPersonSign)}</div>
                            <span className={styles.vsText}>V.</span>
                            <div className={styles.vsSign}>{getSignIcon(dss.secondPersonSign)}</div>
                        </div>
                    </div>
                ) : (
                    <>
                        {previousLine && (
                            <div className={`${styles.lineDisplay}`}>
                                <span className={styles.lineCharacter}>
                                    {previousLine.extra_data.character}:{' '}
                                </span>
                                <span className={styles.lineText}>
                                    {previousLine.extra_data.line}
                                </span>
                            </div>
                        )}
                        {currentLine && (
                            <div
                                className={`${styles.lineDisplay} ${styles.currentLine}`}
                                ref={currentLineRef}
                            >
                                <span className={styles.lineCharacter}>
                                    {currentLine.extra_data.character}:{' '}
                                </span>
                                <span className={styles.lineText}>
                                    {currentLine.extra_data.line}
                                </span>
                            </div>
                        )}
                        {nextLine && (
                            <div className={`${styles.lineDisplay}`}>
                                <span className={styles.lineCharacter}>
                                    {nextLine.extra_data.character}:{' '}
                                </span>
                                <span className={styles.lineText}>{nextLine.extra_data.line}</span>
                            </div>
                        )}
                    </>
                )}
            </div>

            <div className={styles.mediaPreview}>
                {dss &&
                    dss.scenes.length > 0 &&
                    dss.scenes[outerIndex] &&
                    dss.scenes[outerIndex].items[innerIndex] && (
                        <AudioPlayer
                            ref={audioPlayerRef}
                            src={
                                (dss.scenes[outerIndex].items[innerIndex] as Dialogue).extra_data
                                    .audio_url
                            }
                            onEnded={handleAudioEnded}
                            onPlay={() => setIsPlaying(true)}
                            onPause={() => setIsPlaying(false)}
                            autoPlayAfterSrcChange={false}
                            showJumpControls={false}
                            showFilledVolume={true}
                            customControlsSection={[]}
                            customProgressBarSection={[
                                <label
                                    key="playPause"
                                    className={styles.playButton}
                                    onClick={handlePlayPause}
                                >
                                    {isPlaying ? <BsFillPauseFill /> : <BsFillPlayFill />}
                                </label>,
                                RHAP_UI.CURRENT_TIME,
                                RHAP_UI.PROGRESS_BAR,
                                RHAP_UI.DURATION,
                                RHAP_UI.VOLUME_CONTROLS,
                            ]}
                            {...(isMobile && { customVolumeControls: [] })}
                            className={styles.customAudioPlayer}
                        />
                    )}
            </div>
        </div>
    );
};

export default AudioPlayerComponent;
