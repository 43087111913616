import React from 'react';
import two from './HomeView.module.css';

function ExperimentCard({ glyphSrc, title, description, details, partners }) {
    return (
        <article className={two.experiment}>
            <div className={two.glyph}>
                <img
                    loading="lazy"
                    src={glyphSrc}
                    className={two.glyphImage}
                    alt={`${title} glyph`}
                />
            </div>
            <div className={two.info}>
                <h2 className={two.experimentTitle}>{title}</h2>
                <p className={two.experimentDescription}>{description}</p>
                <p className={two.experimentDetails}>{details}</p>
                <div className={two.partners}>
                    {partners.map((partner, index) => (
                        <img
                            key={index}
                            loading="lazy"
                            src={partner.src}
                            className={two.partnerLogo}
                            alt={partner.alt}
                            style={{
                                aspectRatio: partner.src.includes('ext_6-')
                                    ? '3.33'
                                    : partner.src.includes('ext_7-')
                                    ? '2.71'
                                    : '1.67',
                                width: partner.src.includes('ext_6-')
                                    ? '80px'
                                    : partner.src.includes('ext_7-')
                                    ? '65px'
                                    : '40px',
                            }}
                        />
                    ))}
                </div>
            </div>
        </article>
    );
}

export default ExperimentCard;
