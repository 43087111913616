import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './MenuBar.module.css';

function MenuBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname.split('/').pop();

    const handleTabClick = (tab: string) => {
        navigate(`/first-date/${tab}`);
    };

    return (
        <nav className={styles.menuBar}>
            <ul className={styles.menuList}>
                <li
                    className={`${styles.menuItem} ${currentPath === 'play' ? styles.active : ''}`}
                    onClick={() => handleTabClick('play')}
                >
                    Play
                </li>
                <li
                    className={`${styles.menuItem} ${
                        currentPath === 'create' ? styles.active : ''
                    }`}
                    onClick={() => handleTabClick('create')}
                >
                    Create
                </li>
                <li
                    className={`${styles.menuItem} ${
                        currentPath === 'findings' ? styles.active : ''
                    }`}
                    onClick={() => handleTabClick('findings')}
                >
                    Findings
                </li>
            </ul>
            <button className={styles.exitButton} aria-label="Exit" onClick={() => navigate('/')}>
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/ac9663f1192918ab8b2b5d670bdaf2e1bc913acfd730f45392ce87374b31b84d?apiKey=2aee5e8901e142ed9f5341abe3f09cd7&"
                    alt=""
                    className={styles.exitIcon}
                />
            </button>
        </nav>
    );
}

export default MenuBar;
