import { Project, ProjectImpl, ProjectImplWithStats } from '../models/Project';
import { Message } from '../models/Message';
import Cookies from 'js-cookie';
import { getTitlePrompt } from './prompts';
export const MAIN_API_URL = () => `${process.env.REACT_APP_MAIN_API_URL}`;
export const MAIN_API_GENERATE_TEXT_URL = () => `${MAIN_API_URL()}/generate_text`;
export const DATABASE_URL = () => `${process.env.REACT_APP_DATABASE_API_URL}`;
export const LABS_USER_EMAIL = () => `${process.env.REACT_APP_LABS_USER_EMAIL}`;

const login = async () => {
    try {
        const response = await fetch(`${DATABASE_URL()}/labs/login?email=${LABS_USER_EMAIL()}`, {
            method: 'GET',
        });
        const resp = await response.json();
        Cookies.set('token', resp.session_token);
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

class UnauthorizedError extends Error {}
const fetchFromDatabase = async (endpoint: string, options: RequestInit = {}) => {
    let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };
    if (options.headers) {
        headers = { ...headers, ...options.headers };
    }
    const response = await fetch(`${DATABASE_URL()}/${endpoint}`, {
        ...options,
        headers,
    });
    if (!response.ok) {
        throw new UnauthorizedError(
            `Failed to fetch from database: ${response.status} ${response.statusText}`,
        );
    }
    return response.json();
};

const fetchFromDatabaseWithAuth = async (
    endpoint: string,
    options: RequestInit = {},
    retry = true,
) => {
    let token = Cookies.get('token');
    if (!token) {
        try {
            await login();
        } catch (error) {
            console.error('Error logging in:', error);
            return;
        }
        token = Cookies.get('token');
        if (!token) {
            console.error('No token found');
            return;
        }
    }
    try {
        return await fetchFromDatabase(endpoint, {
            ...options,
            headers: { Authorization: `Bearer ${token}` },
        });
    } catch (error) {
        if (error instanceof UnauthorizedError) {
            Cookies.remove('token');
            if (retry) {
                return await fetchFromDatabaseWithAuth(endpoint, options, false);
            }
        }
        throw error;
    }
};

const getFromDatabase = async (endpoint: string, options: RequestInit = {}) => {
    return fetchFromDatabaseWithAuth(endpoint, { ...options, method: 'GET' });
};

const postToDatabase = async (endpoint: string, json: any, options: RequestInit = {}) => {
    return fetchFromDatabaseWithAuth(endpoint, {
        ...options,
        method: 'POST',
        body: JSON.stringify(json),
    });
};

const postToDatabaseWithoutAuth = async (
    endpoint: string,
    json: any,
    options: RequestInit = {},
) => {
    return fetchFromDatabase(endpoint, {
        ...options,
        method: 'POST',
        body: JSON.stringify(json),
    });
};

const putToDatabase = async (endpoint: string, json: any, options: RequestInit = {}) => {
    return fetchFromDatabaseWithAuth(endpoint, {
        ...options,
        method: 'PUT',
        body: JSON.stringify(json),
    });
};

export const generateTitle = async (dss: ProjectImplWithStats): Promise<string> => {
    const prompt = getTitlePrompt(dss.scenes.map(scene => scene.text));
    return generateText([{ role: 'user', content: prompt }]);
};

const generateText = async (messages: Message[]): Promise<string> => {
    try {
        const response = await fetch(MAIN_API_GENERATE_TEXT_URL(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ messages }),
        });

        if (!response.ok) {
            const error = await response.json();
            console.error('Error generating text:', error.detail);
            return;
        }

        const data = await response.json();
        return data.content;
    } catch (error) {
        console.error('Error generating text:', error);
    }
};

type scriptModifierFn = (script: string[]) => string[];
export const generateScript = async (
    prompts: string[],
    setScript: (scriptModifier: scriptModifierFn) => void,
) => {
    const messages = [
        { role: 'system', content: prompts[0] },
        { role: 'user', content: `${prompts[1]}\n${prompts[2]}` },
    ] as Message[];

    const sceneOneContent = await generateText(messages);
    setScript(prevScript => [...prevScript, sceneOneContent]);
    messages.push({ role: 'assistant', content: sceneOneContent } as Message);
};

export const saveProject = async (project: ProjectImplWithStats) => {
    // TODO add upsert method in DB
    const endpoint = project.id ? 'v2/update_project' : 'v2/create_project';
    const method = project.id ? putToDatabase : postToDatabase;
    const savedProject = await method(endpoint, project);
    return new ProjectImplWithStats(savedProject);
};

export const loadProjects = async () => {
    let projects: ProjectImplWithStats[];
    try {
        projects = (await getFromDatabase('v2/get_user_projects')) || [];
    } catch (error) {
        console.error('Error loading projects:', error);
        projects = [];
    }
    return projects.map(project => new ProjectImplWithStats(project));
};

export const loadProject = async (projectId: string) => {
    let projectRaw: ProjectImplWithStats;
    try {
        projectRaw = (await getFromDatabase(`v2/get_project?id=${projectId}`)) || null;
    } catch (error) {
        console.error('Error loading project:', error);
        projectRaw = null;
    }
    return projectRaw ? new ProjectImplWithStats(projectRaw) : null;
};

export type FeedbackRequest = {
    feedback: string;
    from_name: string | null;
    from_email: string | null;
};

export const sendFeedback = async (request: FeedbackRequest) => {
    return postToDatabaseWithoutAuth('labs/feedback', request);
};
