import React from 'react';
import styles from './HomeView.module.css';
import { Link, useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();

    return (
        <header className={styles.header}>
            <Link className={styles.headerHome} onClick={() => navigate('/')} to={'/'}>
                <div className={styles.logoContainer}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="44"
                        height="40"
                        viewBox="0 0 44 40"
                        fill="none"
                    >
                        <path
                            d="M23.7491 36.1266V14.5354L17.9384 16.2586V16.6688L18.2442 16.8603C18.6612 17.0791 19.1617 17.8997 19.1617 18.9117V36.1266C19.1617 37.713 18.7168 38.7798 17.7994 39.3542V39.7645H25.1114V39.3542C24.1939 38.7251 23.7491 37.631 23.7491 36.1266Z"
                            fill="#000000"
                        />
                        <path
                            d="M34.7135 18.8023C34.147 15.7731 32.2321 13.1336 29.6743 11.3044C21.8931 5.73135 9.70866 7.17418 3.49828 14.3918C1.91354 16.2346 0.88832 18.5391 0.589444 20.9358C0.290567 23.3189 0.735407 25.784 2.0039 27.8491C3.39054 30.1057 5.63907 31.8152 8.39152 31.9485C9.86157 32.0203 11.3386 31.5895 12.5793 30.8066C13.8165 30.0236 14.8208 28.9022 15.5194 27.6269C16.1137 26.5499 16.5029 25.3019 16.2666 24.0984C16.0337 22.8949 15.0467 21.7803 13.8026 21.6812C12.9025 21.6094 11.985 22.1051 11.554 22.8881C11.1266 23.671 11.2065 24.6933 11.7487 25.4045C12.2908 26.1157 13.2604 26.4815 14.1466 26.3174C14.581 27.0251 14.3551 28.0064 13.7435 28.5739C13.1353 29.1415 12.2317 29.3296 11.3977 29.2201C10.1535 29.056 8.92671 28.5329 7.98838 27.6918C6.99096 26.796 6.48705 25.5925 6.35498 24.2831C6.24725 23.1924 6.37236 22.0846 6.61216 21.0179C7.12303 18.7169 8.29768 16.5765 9.8859 14.8226C11.4672 13.072 13.4898 11.6771 15.7522 10.9249C16.7357 10.5966 17.7644 10.3949 18.8001 10.3368C22.8349 10.118 26.8524 11.9643 28.6769 15.6055C30.2477 18.7408 30.5814 22.7889 28.6004 25.8456C27.7525 27.155 26.4423 28.1055 24.8575 28.1055C24.4092 28.1055 23.8393 28.0098 23.662 27.5243C23.53 27.1619 23.6029 26.7687 23.6064 26.396C23.6064 26.1909 23.6064 25.9857 23.6064 25.784H23.5856C23.5856 25.8079 23.5856 25.8285 23.5856 25.8524C23.5751 26.5875 23.4466 27.3192 23.4605 28.0543C23.4674 28.5363 23.4292 29.1689 23.6446 29.6168C23.881 30.1159 24.5239 30.304 25.0244 30.4202C25.8306 30.6083 26.6716 30.5775 27.4918 30.5296C28.2286 30.4852 29.0244 30.092 29.6569 29.7467C30.3242 29.3808 30.9428 28.9261 31.4919 28.4064C33.5389 26.461 34.8004 23.7189 34.8769 20.9153C34.8977 20.1905 34.8386 19.4827 34.71 18.7955L34.7135 18.8023Z"
                            fill="#000000"
                        />
                        <path
                            d="M28.6429 7.66392C32.7455 7.66392 36.0714 4.33804 36.0714 0.235352C36.0714 4.33804 39.3973 7.66392 43.5 7.66392C39.3973 7.66392 36.0714 10.9898 36.0714 15.0925C36.0714 10.9898 32.7455 7.66392 28.6429 7.66392Z"
                            fill="#051966"
                        />
                    </svg>
                </div>
                <div className={styles.headerContent}>
                    <span className={styles.headerContentPickford}>PICKFORD |</span>
                    <span className={styles.headerContentLabs}>LABS</span>
                </div>
            </Link>
        </header>
    );
}

export default Header;
