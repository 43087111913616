import React, { useState, useEffect, useRef } from 'react';
import styles from './PairUp.module.css';
import sharedStyles from '../Shared.module.css';
import StarSignSelector from './StarSignSelector';
import { generateScript } from '../../../utils/backendInterface';
import { starSigns } from '../../../utils/starSignInformation';
import {
    getCharacterPrompt,
    getSingleSceneSystem,
    SINGLE_SCENE_USER,
} from '../../../utils/prompts';

let defaultFirstPersonName = '';
let defaultSecondPersonName = '';
if (window.origin && window.origin.includes('localhost')) {
    defaultFirstPersonName = 'Lieutenant Garcia';
    defaultSecondPersonName = 'Captain Smith';
}

let allowPromptEdit = false;
if (window.origin && (window.origin.includes('localhost') || window.origin.includes('labs.dev'))) {
    allowPromptEdit = true;
}

const PairUp = ({
    onForward,
    setScript,
    setScriptUpdated,
    setLoading,
    loading,
    setFirstPersonSign,
    setSecondPersonSign,
    firstPersonSign,
    secondPersonSign,
}) => {
    const [firstPersonName, setFirstPersonName] = useState(defaultFirstPersonName);
    const [secondPersonName, setSecondPersonName] = useState(defaultSecondPersonName);
    const [firstPersonRedFlag, setFirstPersonRedFlag] = useState('');
    const [secondPersonRedFlag, setSecondPersonRedFlag] = useState('');
    const [showPromptEdit, setShowPromptEdit] = useState(false);
    const [scriptStyle, setScriptStyle] = useState('British dark comedy');

    const systemPromptRef = useRef(null);
    const userPromptRef = useRef(null);

    const handleKeyDown = event => {
        // Detect Cmd (Mac) or Ctrl (Windows/Linux) with the 'E' key
        if ((event.metaKey || event.ctrlKey) && event.key === 'e') {
            event.preventDefault(); // Prevent default behavior if necessary
            console.log('Cmd + E or Ctrl + E was pressed');
            setTimeout(() => setShowPromptEdit(prev => !prev), 0);

            // Handle your action here
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const [scenePrompts, setScenePrompts] = useState([
        getSingleSceneSystem(scriptStyle),
        getCharacterPrompt(firstPersonName, firstPersonSign, secondPersonName, secondPersonSign),
        SINGLE_SCENE_USER,
    ]);

    useEffect(() => {
        const contentChange = getCharacterPrompt(
            firstPersonName,
            firstPersonSign,
            secondPersonName,
            secondPersonSign,
        );
        handleInputChange(1, contentChange);
    }, [firstPersonName, secondPersonName, firstPersonSign, secondPersonSign]);

    useEffect(() => {
        const contentChange = getSingleSceneSystem(scriptStyle);
        handleInputChange(0, contentChange);
    }, [scriptStyle]);

    const handleInputChange = (index, content) => {
        const newEditableMessages = [...scenePrompts];
        newEditableMessages[index] = content;
        setScenePrompts(newEditableMessages);
    };

    const handleSelectSign = (person, sign) => {
        const selectedSign = starSigns.find(s => s.name === sign);
        if (person === 'first') {
            setFirstPersonSign(sign);
            setFirstPersonRedFlag(selectedSign.redFlag);
        } else if (person === 'second') {
            setSecondPersonSign(sign);
            setSecondPersonRedFlag(selectedSign.redFlag);
        }
    };

    const handlePromptChange = (index, value) => {
        setScenePrompts(prevPrompts => {
            const newPrompts = [...prevPrompts];
            newPrompts[index] = value;
            return newPrompts;
        });
    };

    const handleSubmit = async e => {
        setLoading(true);
        e.preventDefault();
        setScript([]);
        await generateScript(scenePrompts, setScript);
        setScriptUpdated(true);
        onForward();
        setLoading(false);
    };

    const isFormComplete =
        firstPersonName && secondPersonName && firstPersonSign && secondPersonSign;

    return (
        <form className={sharedStyles.form}>
            <div className={styles.formField}>
                <label htmlFor="firstPersonName" className={styles.formLabel}>
                    FIRST PERSON&apos;S NAME
                </label>
                <input
                    type="text"
                    id="firstPersonName"
                    className={sharedStyles.formInput}
                    aria-label="Enter first person's name"
                    value={firstPersonName}
                    onChange={e => setFirstPersonName(e.target.value)}
                />
            </div>
            <StarSignSelector
                title="FIRST PERSON'S STAR SIGN"
                selectedSign={firstPersonSign}
                onSelectSign={sign => handleSelectSign('first', sign)}
                personSign={firstPersonSign}
                personRedFlag={firstPersonRedFlag}
            />

            <div className={styles.formField}>
                <label htmlFor="secondPersonName" className={styles.formLabel}>
                    SECOND PERSON&apos;S NAME
                </label>
                <input
                    type="text"
                    id="secondPersonName"
                    className={sharedStyles.formInput}
                    aria-label="Enter second person's name"
                    value={secondPersonName}
                    onChange={e => setSecondPersonName(e.target.value)}
                />
            </div>
            <StarSignSelector
                title="SECOND PERSON'S STAR SIGN"
                selectedSign={secondPersonSign}
                onSelectSign={sign => handleSelectSign('second', sign)}
                personSign={secondPersonSign}
                personRedFlag={secondPersonRedFlag}
            />

            <div className={sharedStyles.formField}>
                <label htmlFor="scriptStyle" className={sharedStyles.formLabel}>
                    SCRIPT INFLUENCE / STYLE
                </label>
                <input
                    type="text"
                    id="scriptStyle"
                    className={sharedStyles.formInput}
                    aria-label="Script Style"
                    value={scriptStyle}
                    onChange={e => setScriptStyle(e.target.value)}
                />
            </div>

            {showPromptEdit && (
                <div className={styles.scriptEditContainer}>
                    <label htmlFor="systemPrompt" className={sharedStyles.formLabel}>
                        System Prompt
                    </label>
                    <textarea
                        id="systemPrompt"
                        className={styles.scriptEditTextArea}
                        aria-label="systemPrompt"
                        value={scenePrompts[0]}
                        onChange={e => handlePromptChange(0, e.target.value)}
                    />
                    <label htmlFor="userPrompt" className={sharedStyles.formLabel}>
                        User Prompt
                    </label>
                    <textarea
                        id="userPrompt"
                        className={styles.scriptEditTextArea}
                        aria-label="userPrompt"
                        value={scenePrompts[1]}
                        readOnly
                    />
                    <textarea
                        id="userPrompt"
                        className={styles.scriptEditTextArea}
                        aria-label="userPrompt"
                        value={scenePrompts[2]}
                        onChange={e => handlePromptChange(2, e.target.value)}
                    />
                </div>
            )}

            <div className={sharedStyles.buttonRow}>
                <button
                    type="submit"
                    className={`${sharedStyles.button} ${
                        isFormComplete ? sharedStyles['active-primary'] : sharedStyles['inactive']
                    }`}
                    disabled={!isFormComplete}
                    onClick={handleSubmit}
                >
                    <span className={sharedStyles.buttonText}>GENERATE SCRIPT</span>
                    <div className={sharedStyles.buttonIcon}>
                        {!loading ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={sharedStyles.buttonIconImage}
                                width="30px"
                                height="30px"
                                viewBox="0 0 24 24"
                            >
                                <g transform="scale(1.2) translate(2,1.5)">
                                    <path
                                        id="Exit"
                                        d="M8.5 1L16.5 9M16.5 9L8.5 17M16.5 9H1"
                                        strokeLinecap="round"
                                        strokeWidth="1.3"
                                    />
                                </g>
                            </svg>
                        ) : (
                            <svg
                                fill="hsl(228, 97%, 42%)"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                className={sharedStyles.buttonIconImage}
                            >
                                <path
                                    d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                                    opacity=".25"
                                />
                                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z">
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        dur="0.75s"
                                        values="0 12 12;360 12 12"
                                        repeatCount="indefinite"
                                    />
                                </path>
                            </svg>
                        )}
                    </div>
                </button>
            </div>
        </form>
    );
};

export default PairUp;
