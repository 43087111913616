import {
    TbZodiacAquarius,
    TbZodiacAries,
    TbZodiacCancer,
    TbZodiacCapricorn,
    TbZodiacGemini,
    TbZodiacLeo,
    TbZodiacLibra,
    TbZodiacPisces,
    TbZodiacSagittarius,
    TbZodiacScorpio,
    TbZodiacTaurus,
    TbZodiacVirgo,
} from 'react-icons/tb';

export const starSigns = [
    {
        name: 'Aquarius',
        icon: TbZodiacAquarius,
        dateRange: 'January 20 - February 18',
        redFlag: 'Impulsive: Acts without thinking.',
    },
    {
        name: 'Pisces',
        icon: TbZodiacPisces,
        dateRange: 'February 19 - March 20',
        redFlag: 'Stubborn: Refuses to budge.',
    },
    {
        name: 'Aries',
        icon: TbZodiacAries,
        dateRange: 'March 21 - April 19',
        redFlag: 'Inconsistent: Hot and cold.',
    },
    {
        name: 'Taurus',
        icon: TbZodiacTaurus,
        dateRange: 'April 20 - May 20',
        redFlag: 'Clingy: Overly attached.',
    },
    {
        name: 'Gemini',
        icon: TbZodiacGemini,
        dateRange: 'May 21 - June 21',
        redFlag: 'Self-centered: Loves the spotlight.',
    },
    {
        name: 'Cancer',
        icon: TbZodiacCancer,
        dateRange: 'June 22 - July 22',
        redFlag: 'Overcritical: Nitpicks constantly.',
    },
    {
        name: 'Leo',
        icon: TbZodiacLeo,
        dateRange: 'July 23 - August 22',
        redFlag: 'Indecisive: Struggles to choose.',
    },
    {
        name: 'Virgo',
        icon: TbZodiacVirgo,
        dateRange: 'August 23 - September 22',
        redFlag: 'Jealous: Easily suspicious.',
    },
    {
        name: 'Libra',
        icon: TbZodiacLibra,
        dateRange: 'September 23 - October 23',
        redFlag: 'Commitment-phobic: Dodges serious ties.',
    },
    {
        name: 'Scorpio',
        icon: TbZodiacScorpio,
        dateRange: 'October 24 - November 21',
        redFlag: 'Workaholic: Career over love.',
    },
    {
        name: 'Sagittarius',
        icon: TbZodiacSagittarius,
        dateRange: 'November 22 - December 21',
        redFlag: 'Detached: Emotionally distant.',
    },
    {
        name: 'Capricorn',
        icon: TbZodiacCapricorn,
        dateRange: 'December 22 - January 19',
        redFlag: 'Escapist: Avoids reality.',
    },
];
