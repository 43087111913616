export interface AdvancedPrompt {
    genre: string | null;
    tone: number | null;
    plot: string | null;
    setting: string | null;
    cinematography: string | null;
    theme: string | null;
    pace: number | null;
    dialogue_style: string | null;
    ending: string | null;
}
export interface CameraDirectionData {
    shot_type: ShotType;
    movement_type: MovementType;
    subject: string | null;
}

export interface CharacterMovementData {
    character: string | null;
    end_location: string | null;
    movement_style: string | null;
}

export interface DialogueData {
    character: string | null;
    parenthetical: string | null;
    line: string | null;
    audio_url: string | null;
}

export interface SceneItemBase {
    type: string;
    description: string | null;
    extra_data: CharacterMovementData | CameraDirectionData | DialogueData | null;
    order: number | null;
}

export class SceneItemBaseImpl implements SceneItemBase {
    constructor(
        public type: string,
        public description: string | null,
        public extra_data: CharacterMovementData | CameraDirectionData | DialogueData | null,
        public order: number | null,
    ) {}
}
export type SceneSetting = SceneItemBase;

export class SceneSettingImpl implements SceneSetting {
    type = 'SceneSetting';
    extra_data = null;
    constructor(public description: string | null, public order: number | null) {}
}

export type SceneDescription = SceneItemBase;

export class SceneDescriptionImpl implements SceneDescription {
    type = 'SceneDescription';
    extra_data = null;
    constructor(public description: string | null, public order: number | null) {}
}

export interface ShotType {
    CLOSEUP: 'closeup';
    MEDIUM_CLOSEUP: 'medium closeup';
    WIDE: 'wide';
    MEDIUM_SHOT: 'medium';
    ESTABLISHING: 'establishing';
    TWO: 'two';
    THREE: 'three';
    FULL: 'full';
}

export interface MovementType {
    STATIC: 'static';
    DOLLY_IN: 'dolly in';
    DOLLY_OUT: 'dolly out';
    PANNING: 'panning';
    SLOW_ZOOM_OUT: 'slow zoom out';
    SLOW_ZOOM_IN: 'slow zoom in';
    ZOOM_IN: 'zoom in';
    ZOOM_OUT: 'zoom out';
}

export interface CameraDirection extends SceneItemBase {
    extra_data: CameraDirectionData;
}

export class CameraDirectionImpl implements CameraDirection {
    type = 'CameraDirection';
    constructor(
        public description: string | null,
        public extra_data: CameraDirectionData,
        public order: number | null,
    ) {}
}

export interface CharacterMovement extends SceneItemBase {
    extra_data: CharacterMovementData;
}

export class CharacterMovementImpl implements CharacterMovement {
    type = 'CharacterMovement';
    constructor(
        public description: string | null,
        public extra_data: CharacterMovementData,
        public order: number | null,
    ) {}
}

export interface Dialogue extends SceneItemBase {
    extra_data: DialogueData;
}

export interface OldDialogue extends SceneItemBase {
    order: number | null;
    character: string | null;
    parenthetical: string | null;
    line: string | null;
    audio_url: string | null;
}

export class DialogueImpl implements Dialogue {
    type = 'Dialogue';
    description: string | null;
    extra_data: DialogueData;
    order: number | null;
    constructor(dialogue: Dialogue) {
        Object.assign(this, dialogue);
    }

    static fromOldDialogue(oldDialogue: OldDialogue): DialogueImpl {
        return new DialogueImpl({
            type: 'Dialogue',
            description: oldDialogue.parenthetical,
            extra_data: {
                line: oldDialogue.line,
                character: oldDialogue.character,
                audio_url: oldDialogue.audio_url,
            } as DialogueData,
            order: oldDialogue.order,
        } as Dialogue);
    }

    toOldDialogue(): OldDialogue {
        return {
            line: this.extra_data.line,
            character: this.extra_data.character,
            audio_url: this.extra_data.audio_url,
            order: this.order,
            parenthetical: this.description,
            type: 'Dialogue',
        } as OldDialogue;
    }
}

export interface Transition extends SceneItemBase {
    type: string;
}

export class TransitionImpl implements Transition {
    type = 'Transition';
    extra_data = null;
    constructor(public description: string | null, public order: number | null) {}
}

export type SceneItemTypeUnion =
    | SceneSetting
    | SceneDescription
    | CameraDirection
    | CharacterMovement
    | Dialogue
    | Transition;

export interface Scene {
    text: string;
    items: SceneItemTypeUnion[];
}

export interface Character {
    id: string;
    name: string;
    visual_description: string;
    personality_summary: string;
    motivation: string;
    image_2d_url?: string | null;
    image_3d_url?: string | null;
    image_3d_obj_file_name?: string | null;
    image_3d_mtl_file_name?: string | null;
    image_3d_img_file_name?: string | null;
    image_3d_zip_file_url?: string | null;
    rigged_file_url?: string | null;
    voice_id?: string | null;
}

export class CharacterImpl implements Character {
    public id: string;
    public name: string;
    public visual_description: string;
    public personality_summary: string;
    public motivation: string;
    public image_2d_url: string | null;
    public image_3d_url: string | null;
    public image_3d_obj_file_name: string | null;
    public image_3d_mtl_file_name: string | null;
    public image_3d_img_file_name: string | null;
    public image_3d_zip_file_url: string | null;
    public rigged_file_url: string | null;
    public voice_id: string | null;
    constructor(character: Character);
    constructor(
        idOrCharacter: string | Character,
        name?: string,
        visual_description?: string,
        personality_summary?: string,
        motivation?: string,
        image_2d_url?: string | null,
        image_3d_url?: string | null,
        image_3d_obj_file_name?: string | null,
        image_3d_mtl_file_name?: string | null,
        image_3d_img_file_name?: string | null,
        image_3d_zip_file_url?: string | null,
        rigged_file_url?: string | null,
        voice_id?: string | null,
    ) {
        if (typeof idOrCharacter === 'object') {
            Object.assign(this, idOrCharacter);
        } else {
            this.id = idOrCharacter;
            this.name = name!;
            this.visual_description = visual_description!;
            this.personality_summary = personality_summary!;
            this.motivation = motivation!;
            this.image_2d_url = image_2d_url!;
            this.image_3d_url = image_3d_url!;
            this.image_3d_obj_file_name = image_3d_obj_file_name!;
            this.image_3d_mtl_file_name = image_3d_mtl_file_name!;
            this.image_3d_img_file_name = image_3d_img_file_name!;
            this.image_3d_zip_file_url = image_3d_zip_file_url!;
            this.rigged_file_url = rigged_file_url!;
            this.voice_id = voice_id!;
        }
    }
    get string_description(): string {
        return `<visual_description>${this.visual_description}</visual_description><personality_summary>${this.personality_summary}</personality_summary><motivation>${this.motivation}</motivation>`;
    }
}

export type MusicOption = {
    name: string;
    url: string;
};

export type Credit = {
    name: string;
    role: string;
};

export interface Project {
    id: string;
    credits?: Credit[];
    title: string; // project (e.g. script) title
    extra_data: any;
    created_at: Date;
    updated_at: Date;
    image: string;
    description: string;
    basic_prompt: string;
    advanced_prompt: AdvancedPrompt;
    scenes: Scene[];
    scene_count: number;
    characters: Character[];
}
export class ProjectImpl implements Project {
    public id: string;
    public credits?: Credit[];
    public title: string;
    public extra_data: any;
    public created_at: Date;
    public updated_at: Date;
    public image: string;
    public description: string;
    public basic_prompt: string;
    public advanced_prompt: AdvancedPrompt;
    public scenes: Scene[];
    public scene_count: number;
    public characters: Character[];
    constructor(project: Project);
    constructor(
        idOrProject: string | Project,
        credits?: Credit[],
        title?: string,
        extra_data?: any,
        created_at?: Date,
        updated_at?: Date,
        image?: string,
        description?: string,
        basic_prompt?: string,
        advanced_prompt?: AdvancedPrompt,
        scenes?: Scene[],
        scene_count?: number,
        characters?: Character[],
    ) {
        if (typeof idOrProject === 'object') {
            // If an object is provided, copy its properties
            Object.assign(this, idOrProject);
        } else {
            // If individual arguments are provided, assign them directly
            this.id = idOrProject;
            this.credits = credits;
            this.title = title!;
            this.extra_data = extra_data!;
            this.created_at = created_at!;
            this.updated_at = updated_at!;
            this.image = image!;
            this.description = description!;
            this.basic_prompt = basic_prompt!;
            this.advanced_prompt = advanced_prompt!;
            this.scenes = scenes!;
            this.scene_count = scene_count!;
            this.characters = characters!;
        }
    }

    static fromOldDss(obj: any): ProjectImpl {
        const scenes = obj.scenes.map(scene => {
            return {
                items: scene.items
                    .filter(item => item.type === 'Dialogue')
                    .map(item => DialogueImpl.fromOldDialogue(item as OldDialogue)),
                text: scene.text,
            };
        });
        return new ProjectImpl({
            ...obj,
            scenes,
        });
    }

    dialogueDataItems(): DialogueData[] {
        return this.dialogueItems().map(item => item.extra_data);
    }

    dialogueItems(): Dialogue[] {
        return this.scenes
            .flatMap(scene =>
                scene.items ? scene.items.filter(item => item.type === 'Dialogue') : [],
            )
            .map(
                item =>
                    new DialogueImpl({
                        description: item.description,
                        extra_data: item.extra_data as DialogueData,
                        order: item.order,
                    } as Dialogue),
            );
    }
}

export class ProjectImplWithStats extends ProjectImpl {
    get isSaved(): boolean {
        return this.id !== '' && this.id !== null && this.id !== undefined;
    }
    get creator(): string {
        if (!this.credits) {
            return '';
        }
        return this.credits[0].name;
    }
    set creator(creator: string) {
        if (!this.credits) {
            this.credits = [{ name: creator, role: '' }];
        } else {
            this.credits[0].name = creator;
        }
    }
    get starts(): number {
        return this.extra_data?.starts;
    }
    get plays(): number {
        return this.extra_data?.plays;
    }
    get days(): number {
        if (!this.created_at) {
            return 0;
        }
        let createdAtDate: Date;
        if (!(this.created_at instanceof Date)) {
            createdAtDate = new Date(this.created_at);
        } else {
            createdAtDate = this.created_at;
        }
        const currentDate = new Date();
        const timeDiff = currentDate.getTime() - createdAtDate.getTime();
        return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    }
    get pairing(): string {
        if (!this.extra_data?.firstPersonSign || !this.extra_data?.secondPersonSign) {
            return '';
        }
        return this.extra_data?.firstPersonSign + ' and ' + this.extra_data?.secondPersonSign;
    }
    get firstPersonSign(): string {
        return this.extra_data?.firstPersonSign;
    }
    get secondPersonSign(): string {
        return this.extra_data?.secondPersonSign;
    }
    get script(): string[] {
        return this.scenes?.map(scene => scene.text) || [];
    }
    get scriptText(): string {
        return this.script.join('\n\n---\n\n');
    }
    get music(): MusicOption | null {
        return this.extra_data?.music || null;
    }
}
