import React, { ReactNode } from 'react';
import MenuBar from './MenuBar';
import styles from './MainContent.module.css';

interface MainContentProps {
    children?: ReactNode;
}

function MainContent({ children }: MainContentProps) {
    return (
        <section className={styles.mainContent}>
            <MenuBar />
            {children}
        </section>
    );
}

export default MainContent;
