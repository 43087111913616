import React from 'react';
import HomeView from '../components/Labs/HomeView';
import FirstDateView from '../components/FirstDate/FirstDateView';
import PlayContent from '../components/FirstDate/PlayView/PlayContent';
import CreateContent from '../components/FirstDate/CreateView/CreateContent';
import FindingsContent from '../components/FirstDate/FindingsView/FindingsContent';

export interface RouteConfig {
    path: string;
    element: React.ReactNode;
    backgroundColor: string;
    children?: Omit<RouteConfig, 'backgroundColor'>[];
}

export const routes: RouteConfig[] = [
    {
        path: '/',
        element: <HomeView />,
        backgroundColor: '#3D4253',
    },
    {
        path: '/first-date/*',
        element: <FirstDateView />,
        backgroundColor: '#251B4F',
        children: [
            {
                path: 'play',
                element: <PlayContent />,
            },
            {
                path: 'create',
                element: <CreateContent />,
            },
            {
                path: 'findings',
                element: <FindingsContent />,
            },
        ],
    },
];
