import React from 'react';
import styles from './Sidebar.module.css';

function Sidebar() {
    return (
        <aside className={styles.sidebar}>
            <div className={styles.glyph}>
                <img
                    loading="lazy"
                    src="/experiments/first_date/glyph.png"
                    alt="First Date Experiment Glyph"
                    className={styles.glyphImage}
                />
            </div>
            <div className={styles.info}>
                <h2 className={styles.title}>The First Date</h2>
                <p className={styles.description}>
                    You decide their star signs, but fate decides if they can stand the idea of
                    seeing each other again.
                </p>
                <div className={styles.partners}>
                    <img
                        loading="lazy"
                        src="/partners/DSS.svg"
                        alt="DSS Logo"
                        className={styles.partnerImage}
                    />
                    <img
                        loading="lazy"
                        src="/partners/ElevenLabs.svg"
                        alt="Eleven Labs Logo"
                        className={styles.partnerImage}
                    />
                    <img
                        loading="lazy"
                        src="/partners/OpenAI.svg"
                        alt="OpenAI Logo"
                        className={styles.partnerImage}
                    />
                </div>
            </div>
        </aside>
    );
}

export default Sidebar;
