import React from 'react';
import { Link } from 'react-router-dom';
import styles from './MediaCard.module.css';
import { ProjectImplWithStats } from '../../../models/Project';
import AudioPlayerComponent from '../AudioPlayerComponent';

type MediaCardProps = {
    project: ProjectImplWithStats;
    onProjectStatsUpdated?: (updatedProject: ProjectImplWithStats) => void;
};

function MediaCard({ project, onProjectStatsUpdated }: MediaCardProps) {
    return (
        <article className={styles.mediaCard}>
            <div className={styles.mediaPreview}>
                <AudioPlayerComponent dss={project} onProjectSaved={onProjectStatsUpdated} />
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca563d8a1ba727bdff814f75961f4098aff1ff7d9ad2f3f563122aa65fc9ee66?apiKey=2aee5e8901e142ed9f5341abe3f09cd7&"
                    alt=""
                    className={styles.previewIcon}
                />
            </div>

            <Link to={`/first-date/create?id=${project.id}`} className={styles.cardContent}>
                <h3 className={styles.cardTitle}>{project.title}</h3>
                <p className={styles.cardPairing}>{project.pairing}</p>
                {project.creator && (
                    <p className={styles.cardCreator}>Created by {project.creator}</p>
                )}
                <p className={styles.cardStats}>
                    <span className={styles.statBold}>{project.days || 0} </span>
                    <span className={styles.statNormal}>days </span>
                    <span className={styles.statBold}>{project.starts || 0} </span>
                    <span className={styles.statNormal}>starts </span>
                    <span className={styles.statBold}>{project.plays || 0} </span>
                    <span className={styles.statNormal}>views</span>
                </p>
            </Link>
        </article>
    );
}

export default MediaCard;
