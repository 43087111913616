export const getCharacterPrompt = (
    firstPersonName: string,
    firstPersonSign: string,
    secondPersonName: string,
    secondPersonSign: string,
) =>
    `<Characters> \nThe characters are ${firstPersonName}, who is a ${firstPersonSign} and ${secondPersonName}, who is a ${secondPersonSign}. \n</Characters>\n`;

export const getTitlePrompt = (scenes: string[]) => {
    return `Generate a title for the script. The title should be a short 2-5 word phrase that captures the essence of the script. DO NOT MENTION STAR SIGNS OR ASTROLOGY IN THE TITLE. DO NOT ADD QUOTES AROUND THE TITLE. <script>${scenes.join(
        '\n\nNEXT SCENE\n\n',
    )}</script> \n\nTitle:`;
};

export const getSingleSceneSystem = style => `You are the director and writer of a dating reality show scene where two individuals meet for a first date. Write a ${style} style script of this scene. 
Based on their star sign, pick an appropriate strongly negative characteristic for each person, e.g. controlling, insecure, self-obsessed, etc. 
At the beginning of the scene each person should introduce themselves and speak like a completely normal, well-balanced person and this negative characteristic should be completely hidden. As the scene unfolds the negative characteristic will start to subtly reveal itself in little mannerisms, subtle details of recounted stories, or through little details of the conversation.
`;

export const SINGLE_SCENE_USER = `Establish the scene.
The characters arrive at slightly different times, introduce themselves and order drinks. 
Have one character initiate a very brief what is your star sign conversation. The conversation should quickly touch on what sign the person is and what that says about their personality. Do not dwell on astrology, move on to other topics. Do not mention astrology again.
Have them each explain why they are looking for love again and what they are looking for in a partner. This explanation should include wry observations on love and life.
Have them each share what went wrong in their previous relationship. These stories should say as much about them as their ex-partner.
Have them each share their profession and then tell a story about how unbearable their job is. As appropriate, include ONE humorous example from this list; 
A new coworker who has caused turmoil in their workplace. Describe this turmoil and how it was caused.
A manipulative boss who expects them to work every waking hour, and has devious ways of making this happen
A counter-productive office bonding event that went hilariously off-the-rails
HR schedules mandatory sensitivity training that ends up being bizarrely offensive.
A new app is introduced that “gamifies” productivity, assigning points to different tasks, but employees quickly start cheating the system.
Have each character respond to the other’s story in a way that reveals their deep incompatibilities
With rising irritation the date slowly begins to go off the rails, evolving from awkwardness to mutually annoying.
Seizing on one comment, suddenly our two characters engage in a short heated argument!
The date suddenly ends with both people equally incensed at the other, and swearing they are done with dating, love and the other’s star sign.
`;
