import { MAIN_API_URL } from './backendInterface';
import { Character, OldDialogue, DialogueImpl, CharacterImpl } from '../models/Project';

export const parseCharacters = async (script, setError): Promise<Character[]> => {
    const url = `${MAIN_API_URL()}/parse_characters`;

    const bodyData = JSON.stringify({
        script,
    });

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: bodyData,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const charactersData = JSON.parse(data.characters).script_characters;

        return charactersData.map(character => ({
            name: character.name,
            visual_description: character.visual_description,
            personality_summary: character.personality_summary,
            motivation: character.motivation,
        }));
    } catch (error) {
        console.error('parseCharacters error', error);
        setError('Failed to parse characters. Please try again.');
        setTimeout(() => {
            setError('');
        }, 10000);
        return []; // Return an empty array in case of an error
    }
};

export const parseDialogueFromScript = async (
    scene: string,
    characters: Character[],
    setError,
): Promise<DialogueImpl[]> => {
    const url = `${MAIN_API_URL()}/parse_dialog_lines`;
    const charactersNameList = characters.map(character => character.name);

    const request = {
        scene: scene,
        characters: charactersNameList,
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const rawResponse = await response.json();
        const rawDialogueOldItems = rawResponse.dialogues as OldDialogue[];
        const dialogues = rawDialogueOldItems.map(item => DialogueImpl.fromOldDialogue(item));
        return dialogues;
    } catch (error) {
        console.error('parseDialogueFromScript error', error);
        setError('Error parsing dialogue from script.');
        setTimeout(() => {
            setError('');
        }, 10000);
    }
};

export const mainApiPost = async (url: string, body: any) => {
    const response = await fetch(`${MAIN_API_URL()}${url}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const generateAudioWithParsedDialogue = async (
    dialogues: DialogueImpl[],
    characters: Character[],
    setError,
) => {
    const voiceSelectionUrl = '/select_voices';
    const voiceTracksUrl = '/generate_voice_tracks';
    const characterToDescriptionMap = characters.reduce(
        (acc, character) => ({
            ...acc,
            [character.name]: new CharacterImpl(character).string_description,
        }),
        {},
    );
    const characterToVoiceMap = await mainApiPost(voiceSelectionUrl, {
        character_descriptions: characterToDescriptionMap,
        backend_voice_model: 'eleven_multilingual_v2',
    });
    const request = {
        dialogues: dialogues.map(dialogue => dialogue.toOldDialogue()),
        characters: characterToVoiceMap,
    };
    try {
        const rawResponse = await mainApiPost(voiceTracksUrl, request);
        const rawDialogues = rawResponse as OldDialogue[];
        const dialogues = rawDialogues.map(item => DialogueImpl.fromOldDialogue(item));
        return dialogues;
    } catch (error) {
        console.error('generateAudioWithParsedDialogue error', error);
        setError('Error generating dialogue from script.');
        setTimeout(() => {
            setError('');
        }, 10000);
    }
};
