import React, { useEffect } from 'react';
import sharedStyles from '../Shared.module.css';
import { MusicOption } from '../../../models/Project';


export const musicOptions: MusicOption[] = [
    { name: 'Baroque', url: `${process.env.REACT_APP_CDN}/labs_background_music/Echoes of Calm.mp3` },
    { name: 'Jazz', url: `${process.env.REACT_APP_CDN}/labs_background_music/Funky Chill Time.mp3` },
    { name: 'Soft Rock', url: `${process.env.REACT_APP_CDN}/labs_background_music/Rhythm of Restlessness.mp3` },
    { name: 'Ambient', url: `${process.env.REACT_APP_CDN}/labs_background_music/Clouds of Euphoria.mp3` },
    { name: 'Psytrance', url: `${process.env.REACT_APP_CDN}/labs_background_music/Elevated Euphoria.mp3` },
];

export const defaultSelectedMusicOption =
    musicOptions[Math.floor(Math.random() * musicOptions.length)];

type SetTheVibeProps = {
    onForward: () => void;
    onBack: () => void;
    selectedMusicOption: MusicOption | null;
    setSelectedMusicOption: (music: MusicOption) => void;
};

const SetTheVibe = ({
    onForward,
    onBack,
    selectedMusicOption,
    setSelectedMusicOption,
}: SetTheVibeProps) => {
    const handleMusicSelect = (music: MusicOption) => {
        setSelectedMusicOption(music);
    };
    useEffect(() => {
        setSelectedMusicOption(defaultSelectedMusicOption);
    }, []);

    return (
        <form className={sharedStyles.form}>
            <div className={sharedStyles.optionCloud}>
                <h3 className={sharedStyles.optionCloudTitle}>BACKGROUND MUSIC STYLE</h3>
                <div className={sharedStyles.optionCloudList}>
                    {musicOptions.map((music, index) => {
                        return (
                            <button
                                type="button"
                                key={index}
                                className={`music-option ${sharedStyles.option} ${
                                    selectedMusicOption?.name === music.name
                                        ? sharedStyles['selected']
                                        : ''
                                }`}
                                onClick={() => handleMusicSelect(music)}
                            >
                                <span className={sharedStyles.optionTitle}>{music.name}</span>
                            </button>
                        );
                    })}
                </div>
            </div>

            <div className={sharedStyles.buttonRow}>
                <button
                    type="button"
                    className={`${sharedStyles.button} ${sharedStyles['active-secondary']}`}
                    onClick={onBack}
                >
                    <span className={sharedStyles.buttonText}>GO BACK</span>
                </button>
                <button
                    type="submit"
                    className={`${sharedStyles.button} ${sharedStyles['active-primary']}`}
                    onClick={onForward}
                >
                    <span className={sharedStyles.buttonText}>GENERATE</span>
                    <div className={sharedStyles.buttonIcon}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={sharedStyles.buttonIconImage}
                        >
                            <path
                                id="Exit"
                                d="M8.5 1L16.5 9M16.5 9L8.5 17M16.5 9H1"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                </button>
            </div>
        </form>
    );
};

export default SetTheVibe;
