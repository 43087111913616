import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import ExperimentArea from './ExperimentArea';
import PlayContent from './PlayView/PlayContent';
import CreateContent from './CreateView/CreateContent';
import FindingsContent from './FindingsView/FindingsContent';
import Header from '../Labs/Header';
import styles from './FirstDateView.module.css';

function FirstDateView() {
    return (
        <div className={styles.labsPlay}>
            <Header />
            <ExperimentArea>
                <Routes>
                    {/* Redirect /first-date to /first-date/play by default */}
                    <Route path="/" element={<Navigate to="play" replace />} />
                    <Route path="play" element={<PlayContent />} />
                    <Route path="create" element={<CreateContent />} />
                    <Route path="findings" element={<FindingsContent />} />
                </Routes>
            </ExperimentArea>
        </div>
    );
}

export default FirstDateView;
