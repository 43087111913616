import React, { ReactNode } from 'react';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import styles from './ExperimentArea.module.css';

interface ExperimentAreaProps {
    children?: ReactNode;
}

function ExperimentArea({ children }: ExperimentAreaProps) {
    return (
        <main className={styles.experimentArea}>
            <div className={styles.leftSpacer} />
            <div className={styles.content}>
                <div className={styles.experiment}>
                    <Sidebar />
                    <MainContent>{children}</MainContent>
                </div>
            </div>
            <div className={styles.rightSpacer} />
        </main>
    );
}

export default ExperimentArea;
