import React, { useState, useEffect } from 'react';
import styles from './FindingsContent.module.css';
import sharedStyles from '../Shared.module.css';
import { sendFeedback as sendFeedbackToBackend } from '../../../utils/backendInterface';

const findingContent = [
    {
        title: 'Creating a working script',
        subtitle: 'Out of the box GPT scripts were not relatable, and needed tuning.',
        date: '10.28.24',
        finding:
            'To explore AI ‘voice acting’ we needed an easy and fun way for creators to generate a short, dialog heavy script they could work with. To keep things simple, creative options were confined to name and star sign choices. Our first attempts produced an overly energetic, overly witty dialog style and we experimented with a range of ‘system’ prompts to increase the chances of a relatable, entertaining script every time. One of the most effective ‘levers’ for this was to give the AI a writing style to emulate, with ‘british dark comedy’ giving pretty good results. We added a ‘script style’ field to expose this to our creators.',
    },
];

function FindingsContent() {
    const [feedbackSent, setFeedbackSent] = useState(false);
    const [feedbackError, setFeedbackError] = useState('');
    const [fromName, setFromName] = useState('');
    const [fromEmail, setFromEmail] = useState('');

    const sendFeedback = async () => {
        const feedback = (document.getElementById('feedbackContainer') as HTMLTextAreaElement)
            .value;
        if (!feedback.trim()) {
            alert('Please enter your feedback before sending');
            return;
        }

        try {
            await sendFeedbackToBackend({
                feedback: feedback.trim(),
                from_name: fromName.trim() || null,
                from_email: fromEmail.trim() || null,
            });
            (document.getElementById('feedbackContainer') as HTMLTextAreaElement).value = '';
            setFromName('');
            setFromEmail('');
            setFeedbackSent(true);
            return true;
        } catch (error) {
            console.error('Error sending feedback:', error);
            setFeedbackError('Failed to send feedback. Please try again later.');
            return false;
        }
    };

    // Reset states when component unmounts
    useEffect(() => {
        return () => {
            setFeedbackSent(false);
            setFeedbackError('');
        };
    }, []);

    return (
        <div className={styles.findingsContent}>
            <p className={styles.description}>
                Exploring whether entertaining characters and a believable rom-com genre scene can
                be AI generated based on simple inputs. Script and audio only.
            </p>

            <div className={styles.findingsContainer}>
                {findingContent.map((finding, index) => (
                    <div key={index} className={styles.findingCard}>
                        <div className={styles.findingHeader}>
                            <h2 className={styles.findingTitle}>{finding.title}</h2>
                            <span className={styles.findingDate}>{finding.date}</span>
                        </div>
                        <h3 className={styles.findingSubtitle}>{finding.subtitle}</h3>
                        <p className={styles.findingText}>{finding.finding}</p>
                    </div>
                ))}
            </div>

            <p className={styles.description} style={{ fontStyle: 'italic' }}>
                Send us a note below about what worked / did not work, etc. and we will add your
                feedback to our findings.
            </p>
            <div className={styles.feedbackContainer}>
                <label htmlFor="feedbackContainer" className={styles.feedbackLabel}>
                    YOUR FEEDBACK
                </label>
                {feedbackSent && (
                    <div className={styles.feedbackBanner} style={{ backgroundColor: '#4CAF50', color: 'white', padding: '8px', marginBottom: '10px', borderRadius: '4px' }}>
                        Feedback sent successfully!
                    </div>
                )}
                {feedbackError && (
                    <div className={styles.feedbackBanner} style={{ backgroundColor: '#f44336', color: 'white', padding: '8px', marginBottom: '10px', borderRadius: '4px' }}>
                        {feedbackError}
                    </div>
                )}
                <textarea
                    id="feedbackContainer"
                    className={styles.feedbackTextarea}
                    aria-label="Feedback"
                />
                <div className={styles.optionalFields}>
                    <div className={styles.inputField}>
                        <label htmlFor="fromName" className={styles.feedbackLabel}>
                            YOUR NAME (OPTIONAL)
                        </label>
                        <input
                            id="fromName"
                            type="text"
                            className={styles.feedbackTextInput}
                            value={fromName}
                            onChange={(e) => setFromName(e.target.value)}
                            aria-label="Your Name"
                        />
                    </div>
                    <div className={styles.inputField}>
                        <label htmlFor="fromEmail" className={styles.feedbackLabel}>
                            YOUR EMAIL (OPTIONAL)
                        </label>
                        <input
                            id="fromEmail"
                            type="email"
                            className={styles.feedbackTextInput}
                            value={fromEmail}
                            onChange={(e) => setFromEmail(e.target.value)}
                            aria-label="Your Email"
                        />
                    </div>
                </div>
            </div>
            <div className={sharedStyles.buttonRow}>
                <button
                    type="submit"
                    className={`${sharedStyles.button} ${sharedStyles['active-primary']}`}
                    onClick={sendFeedback}
                >
                    <span className={sharedStyles.buttonText}>SEND</span>
                    <div className={sharedStyles.buttonIcon}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={sharedStyles.buttonIconImage}
                        >
                            <path
                                id="Exit"
                                d="M8.5 1L16.5 9M16.5 9L8.5 17M16.5 9H1"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default FindingsContent;
