import React from 'react';
import { useLocation } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import '@fontsource/inter';
import '@fontsource/roboto-mono';
import '@fontsource/poppins';

import { routes } from './routes/routes';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    const location = useLocation();
    const currentRoute = routes.find(route => route.path === location.pathname) || routes[0];

    return (
        <div className="App PickfordLabs">
            <div className="content" style={{ backgroundColor: currentRoute.backgroundColor }}>
                <Routes>
                    {routes.map(({ path, element }) => (
                        <Route key={path} path={path} element={element} />
                    ))}
                    <Route path="*" element={routes[0].element} />
                </Routes>
            </div>
        </div>
    );
}

export default App;
